<template>
  <span
    :class="classes"
    class="Icon"
  >
    <slot />
  </span>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'default',
  },
  size: {
    type: String,
    default: '',
  },
});

// Computed
const classes = computed(() => [
  props.type.length ? `Icon--${props.type}` : undefined,
  props.size.length ? `Icon--${props.size}` : undefined,
]);
</script>

<style scoped>
.Icon {
  width: 40px;
  height: 40px;

  min-width: 40px;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    width: 16px;
    height: auto;
    display: block;
  }

  &--mini {
    width: 22px;
    height: 22px;

    min-width: 22px;
    min-height: 22px;

    svg {
      height: 14px;
      width: 14px;
    }
  }

  &--small {
    width: 32px;
    height: 32px;

    min-width: 32px;
    min-height: 32px;
  }

  &--large {
    width: 48px;
    height: 48px;

    min-width: 48px;
    min-height: 48px;
  }

  &--default {
    @apply tw-bg-light tw-text-text-light;
  }

  &--danger {
    @apply tw-bg-danger-500 tw-text-white;
  }

  &--success {
    @apply tw-bg-success tw-text-white;
  }
}
</style>
